export const navLinks = [
  {
    label: 'Solutions',
    pathname: '/',
    hash: '#solution',
  },
  {
    label: 'WhyUs',
    pathname: '/',
    hash: '#details',
  },
  {
    label: 'Brands',
    pathname: '/brands',
   },
  {
    label: 'Contact',
    pathname: '/contact',
  },
  //  {
  //   label: 'Team',
  //   pathname: '/team',
  // },
];

export const socialLinks = [
  {
   label: 'Mail',
   url: 'mailto:hello@quadato.com',
   icon: 'mail',
  },
  {
    label: 'WhatsApp',
    url: 'http://wa.me/917670999256',
    icon: 'whatsapp',
  },
];
